import React from 'react'
import { joinStringsWithDot, replaceNewlinesWithBr, dateRangeDisplay } from '../../../util'
import defaultInstitution from '../../../assets/defaultInstitution.png'
import InnerHTML from 'dangerously-set-html-content'

import styles from '../Common.module.css'

type Props = {
    poss?: JobPosition[]
}

const Experience = (props: Props) => {
    const { poss } = props
    return (
        <div className={styles.root} id="experience">
            <div className={`card ${styles.cardBody}`}>
                <div className="card__header margin--auto">
                    <h3 style={{ fontSize: 36, marginTop: 8, marginBottom: 0 }}>Experience</h3>
                </div>
                <div style={{ textAlign: `center` }}>
                    {poss && poss.length ?
                        poss.map((pos: JobPosition, i) => {
                            const { description, companyName, companyLogo, title,  location, url, dateRange } = pos
                            const displayedDateRange = dateRangeDisplay(dateRange)
                            const companyLogoFull = companyLogo ?? defaultInstitution
                            return (

                                <div key={i} className={`card__body ${styles.innerCardBody}`}>
                                    <hr style={{ marginTop: 12 }} />
                                    {
                                        companyLogo &&
                                        <div className={`avatar avatar--vertical ${styles.avatarMobileLogo} margin--auto`}>
                                            {url ?
                                                <a href={url} target="_blank" rel="noopener noreferrer" >
                                                    <img className="hoverLarge margin--auto" src={companyLogoFull} alt={companyName} />
                                                </a>
                                                :
                                                <img src={companyLogoFull} alt={companyName} className="margin--auto" />
                                            }
                                        </div>
                                    }
                                    <div className="avatar">
                                        {
                                            <div className={`avatar ${styles.avatarLogo}`}>
                                                {url ?
                                                    <a href={url} target="_blank" rel="noopener noreferrer" style={{ maxWidth: '80%', maxHeight: '80%', margin: 'auto' }} >
                                                        <img className="hoverLarge" src={companyLogoFull} alt={companyName} />
                                                    </a>
                                                    :
                                                    <img src={companyLogoFull} alt={companyName} style={{ maxWidth: '80%', maxHeight: '80%', margin: 'auto' }} />
                                                }
                                            </div>
                                        }
                                        <div className={`avatar__intro ${styles.avatarContent}`}>
                                            <h4 className="mobile-center-full-left avatar__name" style={{ fontSize: 20, marginBottom: 4 }}>{title}</h4>
                                            <div className={styles.centerOnMobile}>
                                                {
                                                    joinStringsWithDot([
                                                        companyName,
                                                        displayedDateRange,
                                                        location
                                                    ])
                                                }
                                            </div>
                                            <div className={styles.jobContent}>
                                                {(description && description.length > 0) && <InnerHTML html={replaceNewlinesWithBr(description)} />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        :

                        <div className={`card__body ${styles.innerCardBody}`}>
                            <hr style={{ marginTop: 12 }} />
                            <h3 style={{ margin: `auto` }}>Add some positions and it'll appear here!</h3>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Experience