import React from 'react'
import styles from '../Common.module.css'


type Prop = {
    skills?: Skill[]
}

const Skills = (props: Prop) => {
    const { skills } = props

    const getSkillTag = (skill: Skill, key: number) => {
        const { name, url } = skill
        const skillContent = (url && url.length > 0) ?
            <a className={styles.noGreenLink} href={url} target="_blank" rel="noopener noreferrer">{name}</a> : <span>{name}</span>
        return (
            <span key={key} className={`badge badge--secondary ${styles.skill}`} style={{ margin: 6, fontSize: `1.2rem`, backgroundColor: `transparent`, color: `black`, borderColor: `var(--ifm-color-gray-700)` }}>
                {skillContent}
            </span>
        )
    }
    return (
        <div className={styles.root} id="skills">
            <div className={`card ${styles.cardBody}`}>
                <div className="card__header margin--auto">
                    <h3 style={{ fontSize: 36, marginTop: 8, marginBottom: 0 }}>Skills</h3>
                </div>
                <div className={`card__body ${styles.innerCardBody}`}>
                    <hr style={{ marginTop: 12 }} />
                    <div style={{ margin: 'auto', textAlign: `center` }}>
                        {
                            (skills && skills.length > 0) ?
                                skills.map(getSkillTag)
                                :
                                <h3>Add some skills and it'll appear here!</h3>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Skills