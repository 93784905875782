const notUndefined = <T>(x: T | undefined): x is T => {
    return x !== undefined;
}

export const joinStringsWithDot = (strs: (string | undefined)[]) => {
    return strs
        .filter(notUndefined)
        .filter(s => s.length > 0)
        .join(` · `)
}

export function replaceNewlinesWithBr(str: string): string {
    return str.toString().replace(/(\r\n|\n|\r)/gm, "<br/>");
}

// const currentYear = (new Date()).getFullYear()

const intMonthToFullMonthMap: Record<number, string> = {
    0: `January`,
    1: `February`,
    2: `March`,
    3: `April`,
    4: `May`,
    5: `June`,
    6: `July`,
    7: `August`,
    8: `September`,
    9: `October`,
    10: `November`,
    11: `December`,
}


export const dateRangeDisplay = (x: DateRange): string => {
    let res: string
    const { monthFrom, monthTo, yearFrom, yearTo, toPresent } = x

    if (!toPresent && monthTo !== undefined && yearTo !== undefined) {
        /// if monthTo and yearTo corrupt, assume present
        const from = `${intMonthToFullMonthMap[monthFrom]} ${yearFrom}`
        if (monthFrom === monthTo && yearFrom === yearTo) {
            res = from
        }
        else {
            const to = `${intMonthToFullMonthMap[monthTo]} ${yearTo}`
            res = `${from} - ${to}`
        }
    }
    else {
        const from = `${intMonthToFullMonthMap[monthFrom]} ${yearFrom}`
        const to = `Present`
        res = `${from} - ${to}`
    }
    return res
}

export const yearRangeDisplay = (x: DateRange): string => {

    let res: string
    const { yearFrom, yearTo, toPresent } = x

    if (!toPresent && yearTo) {
        /// if yearTo corrupt, assume present
        const from = `${yearFrom}`
        if (yearFrom === yearTo) {
            res = from
        }
        else {
            const to = `${yearTo}`
            res = `${from} - ${to}`
        }
    }
    else {
        const from = `${yearFrom}`
        const to = `Present`
        res = `${from} - ${to}`
    }
    return res
}